import { UserProfile } from '@auth0/nextjs-auth0/client';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

type Props = {
  user: UserProfile | undefined;
  error: Error | undefined;
  isAuth0Loading: boolean;
  children: React.ReactNode;
};

/**
 * JSDoc
 * @see 認証を行うコンポーネント
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const Auth = ({ user, error, isAuth0Loading, children }: Props) => {
  const router = useRouter();

  useEffect(() => {
    if (isAuth0Loading) return; // loading中だったら待つ。
    if (!user) {
      // ユーザーがいなければログイン画面に飛ばす
      void (async () => {
        void (await router.push(`/api/auth/login`));
      })();
    }
  }, [user, isAuth0Loading, router]);

  return (
    <>
      {(() => {
        if (user && !error && !isAuth0Loading) {
          return <>{children}</>;
        }

        return null;
      })()}
    </>
  );
};
