import { Box } from '@/components/atoms/Box';
import { useAuthContext } from '@/contexts/AuthContext';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { Feature } from '@/contexts/FeaturesContext/types';
import { useConfirmEraseDataDialog } from '@/features/components/modals/ConfirmEraseDataDialog/hooks/useConfirmEraseDataDialog';
import { AdminMenu } from '@/features/components/modeless/AdminMenu';
import { BackgroundMenu } from '@/features/components/modeless/BackgroundMenu';
import { usePopper } from '@/hooks/local/usePopper';
import { useCallback, useState } from 'react';

export const useMenuHandlers = () => {
  const {
    featureData,
    activateTargetFeature,
    findActiveStep,
    activeFeatureName,
  } = useFeaturesContext({});

  const [menu, setMenu] = useState<React.ReactNode>(<></>);
  const { isOpenPopper, handleOpenPopper, handleClosePopper, anchorElement } =
    usePopper();
  const [isGrabbingSetMenu, setIsGrabbingSetMenu] = useState(false);
  const { user } = useAuthContext();
  const {
    handleOpenConfirmEraseDataDialog,
    setIsOpenConfirmEraseDataDialog,
    setTargetFeatureName,
  } = useConfirmEraseDataDialog();

  /**
   * @see ページ遷移時にバッチ処理のデータがある場合に警告ダイアログを表示する
   * @see コンポーネントを跨ぐためグローバルステートを用いている
   */
  const openDialogWithCheckBatchData = useCallback(
    (name: string): boolean => {
      if (!featureData) {
        setIsOpenConfirmEraseDataDialog(false);

        return false;
      }
      const step = findActiveStep();

      if (
        step &&
        step.name &&
        step.name !== 'batchUpload' &&
        step.name !== 'batchSetting' &&
        step.name !== 'batchDownload' &&
        !(
          step.name === 'batchDownload' &&
          activeFeatureName === 'colorTemperature'
        )
      ) {
        setIsOpenConfirmEraseDataDialog(false);

        return false;
      }

      if (featureData.batch.length) {
        if (
          featureData.batch[0].refImage.refUrl ||
          featureData.batch[0].originalImages.length
        ) {
          setTargetFeatureName(name);
          handleOpenConfirmEraseDataDialog();

          return true;
        }

        setIsOpenConfirmEraseDataDialog(false);

        return false;
      }
      setIsOpenConfirmEraseDataDialog(false);

      return false;
    },
    [
      featureData,
      findActiveStep,
      setIsOpenConfirmEraseDataDialog,
      setTargetFeatureName,
      handleOpenConfirmEraseDataDialog,
      activeFeatureName,
    ],
  );

  const handleClick = useCallback(
    (d: Feature) => {
      if (openDialogWithCheckBatchData(d.main.name)) return;
      if (d.main.name !== 'admin' && d.main.name !== 'background') {
        activateTargetFeature(d.main.name);
        handleClosePopper();
      }
    },
    [activateTargetFeature, openDialogWithCheckBatchData, handleClosePopper],
  );

  const handleOpenSetMenu = useCallback(
    (d: Feature, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (d.main.name === 'admin') {
        setMenu(
          <Box>
            <AdminMenu onClose={handleClosePopper} />
          </Box>,
        );
        handleOpenPopper(e);
        setIsGrabbingSetMenu(true);
      }
      if (d.main.name === 'background') {
        setMenu(
          <Box>
            <BackgroundMenu onClose={handleClosePopper} />
          </Box>,
        );
        handleOpenPopper(e);
        setIsGrabbingSetMenu(true);
      }
    },
    [handleClosePopper, handleOpenPopper],
  );

  const handleMouseOutMenu = useCallback(() => {
    if (!isGrabbingSetMenu) return;
    setIsGrabbingSetMenu(false);
  }, [isGrabbingSetMenu]);

  return {
    menu,
    isOpenPopper,
    anchorElement,
    user,
    handleClick,
    handleOpenSetMenu,
    handleMouseOutMenu,
    handleClosePopper,
    openDialogWithCheckBatchData,
  };
};
