import { FadeIn } from '@/components/molecules/FadeIn';
import { useAppContext } from '@/contexts/AppContext';
import { memo, useEffect, useRef } from 'react';
import { StyledContent, StyledContentPadding } from './styled';

type Props = {
  children: React.ReactNode;
};

/**
 * JSDoc
 * @see Sugekaeのコンテンツ領域
 * @see 初期ロード時にフェードインする
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const Content = memo(({ children }: Props) => {
  const contentRef = useRef(null);
  const {
    setContentRefContext,
    contentElementContext,
    setContentElementContext,
  } = useAppContext();

  useEffect(() => {
    if (!contentRef?.current) return;
    setContentRefContext(contentRef);
    setContentElementContext(contentRef.current);
  }, [contentElementContext, setContentElementContext, setContentRefContext]);

  return (
    <StyledContent ref={contentRef}>
      <StyledContentPadding>
        <FadeIn>{contentRef && <>{children}</>}</FadeIn>
      </StyledContentPadding>
    </StyledContent>
  );
});
