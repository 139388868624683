import { Box } from '@/components/atoms/Box';
import { Dialog } from '@/components/molecules/Dialog';
import {
  StyledFlex,
  StyledFlexEnd,
  StyledFont12Danger,
  StyledFont20BoldDanger,
} from '@/components/styled';
import { StyledDialogErrorOkButton } from '@/components/styled/button';
import { AxiosError } from 'axios';
import { memo, useCallback } from 'react';
import { StyledDialogContainer, StyledErrorCodeBox } from './styled';

type Props = {
  isError: boolean;
  setIsError: (isError: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  axiosError?: AxiosError<unknown | any> | null;
  errorCode?: number | string;
};

/**
 * JSDoc
 * @see エラーダイアログ
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const ConfirmErrorDialog = memo(
  ({ isError, setIsError, axiosError, errorCode }: Props): JSX.Element => {
    const handleCloseDialog = useCallback(() => {
      setIsError(false);
    }, [setIsError]);

    return (
      <Dialog
        maxWidth="sm"
        isOpenDialog={isError}
        onClose={handleCloseDialog}
        onSubmit={handleCloseDialog}
        hasTitle={false}
        hasCloseIcon={false}
      >
        <StyledDialogContainer>
          <StyledFont20BoldDanger>エラーが発生しました</StyledFont20BoldDanger>
          <Box
            dangerouslySetInnerHTML={{
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              __html: axiosError?.response?.data?.message_jp || '',
            }}
          />
          <StyledFlex>
            {errorCode && (
              <StyledErrorCodeBox>
                <StyledFont12Danger>
                  エラーコード:{errorCode}
                </StyledFont12Danger>
              </StyledErrorCodeBox>
            )}
            <StyledFlexEnd>
              <StyledDialogErrorOkButton onClick={handleCloseDialog}>
                OK
              </StyledDialogErrorOkButton>
            </StyledFlexEnd>
          </StyledFlex>
        </StyledDialogContainer>
      </Dialog>
    );
  },
);
