import { Box } from '@/components/atoms/Box';
import { StyledFlexCenter } from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { Feature } from '@/contexts/FeaturesContext/types';
import { Popper } from '@mui/material';
import { Fragment, createElement, memo } from 'react';
import { useMenuHandlers } from './hooks/useMenuHandlers';
import {
  StyledMenu,
  StyledMenuBox,
  StyledMenuIcon,
  StyledMenuLink,
  StyledMenuText,
  StyledPartitionLine,
} from './styled';

/**
 * JSDoc
 * @see メニューコンポーネント
 * @see メニューを表示する際に使用する
 * @returns {JSX.Element}
 */
export const Menu = memo(() => {
  const { featuresContext, activeFeatureName } = useFeaturesContext({});

  const {
    menu,
    isOpenPopper,
    anchorElement,
    handleClick,
    handleOpenSetMenu,
    handleMouseOutMenu,
    handleClosePopper,
  } = useMenuHandlers();

  return (
    <>
      <StyledMenu>
        <StyledMenuBox>
          <Box>
            {(() => (
              <>
                {featuresContext.map((d: Feature, i: number) => (
                  <Fragment key={String(`${d.main.name}-${i}`)}>
                    {d.main.isVisible && d.main.name !== 'partition' && (
                      <Box>
                        <StyledMenuLink
                          isActive={
                            d.main.isActive ||
                            (d.main.name === 'background' &&
                              activeFeatureName === 'backgroundLora')
                          }
                          isDisable={d.main.isDisable}
                          onMouseEnter={(e) => {
                            // eslint-disable-next-line no-unused-expressions
                            !d.main.isDisable && handleOpenSetMenu(d, e);
                          }}
                          onMouseOut={() => {
                            // eslint-disable-next-line no-unused-expressions
                            !d.main.isDisable && handleMouseOutMenu();
                          }}
                          onClick={() => {
                            // eslint-disable-next-line no-unused-expressions
                            !d.main.isDisable && handleClick(d);
                          }}
                        >
                          <Box>
                            <StyledMenuIcon isDisable={d.main.isDisable}>
                              {(() => {
                                if (d.main.icon) {
                                  const iconElement = createElement(
                                    d.main.icon,
                                  );

                                  return iconElement;
                                }

                                return null;
                              })()}
                            </StyledMenuIcon>
                            <StyledMenuText
                              isActive={d.main.isActive}
                              isDisable={d.main.isDisable}
                            >
                              {d.main.menu}
                            </StyledMenuText>
                          </Box>
                        </StyledMenuLink>
                      </Box>
                    )}
                    {d.main.name === 'partition' && (
                      <StyledFlexCenter>
                        <StyledPartitionLine />
                      </StyledFlexCenter>
                    )}
                  </Fragment>
                ))}
              </>
            ))()}
          </Box>
        </StyledMenuBox>
      </StyledMenu>
      <Popper
        open={isOpenPopper}
        anchorEl={anchorElement}
        placement={'right'}
        style={{
          zIndex: 99999999999,
        }}
        onMouseLeave={() => {
          handleClosePopper();
        }}
      >
        {menu}
      </Popper>
    </>
  );
});
